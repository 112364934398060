import { Splide } from '@splidejs/splide';
import { Intersection } from '@splidejs/splide-extension-intersection';


function initProductSplide(document){
  if($('#gallery-top').length){
      var main = new Splide( '.splide#gallery-top', {
          type       : 'loop',
          width: '100%',
          gap        : 10,
          heightRatio: 0.82225008736,
          pagination : false,
          arrows     : false,
          cover      : true,
          lazyLoad: 'nearby',
      } );

      var thumbnails = new Splide( '.splide#gallery-bottom', {
          fixedWidth      : 145,
          fixedHeight     : 100,
          isNavigation    : true,
          gap             : 10,
          focus           : 'left',
          pagination      : false,
          arrows: true,
          arrowPath: 'm2.042 38.333 19.25 -18.333 -19.25 -18.333',
          cover           : true,
          lazyLoad: 'nearby',
          dragMinThreshold: {
              mouse: 4,
              touch: 10,
          },
          breakpoints : {
              640: {
                  fixedWidth  : 100,
                  fixedHeight : 70
              },
          },
      } );

      main.sync( thumbnails );
      main.mount();
      thumbnails.mount();
  }
}

initProductSplide();

// splide
if($('#splide-slideshow').length){
  // slideshow hp
  var splideSlideshow = new Splide( '#splide-slideshow', {
    direction: 'ltr',
    autoHeight: true,
    type: 'loop',
    lazyLoad: 'nearby',
    pagination : true,
    arrows     : true,
    autoplay: 'pause',
    intersection: {
      inView: {
        autoplay: true,
      },
      outView: {
        autoplay: false,
      },
    },
    interval: 7000,
    arrowPath: 'm2.042 38.333 19.25 -18.333 -19.25 -18.333'
  });

  splideSlideshow.mount( { Intersection } );
}

$(document).ready(function() {

  $.nette.ext({
    success: function(payload) {
      initProductSplide();
    },
  });

});

window.addEventListener("popstate", (event) => {
  console.log('popstate');
  setTimeout(initProductSplide,0);
});